document.addEventListener('DOMContentLoaded', function () {
    const fancyImages = document.querySelectorAll('.team-image__fancy');

    if (fancyImages.length > 0) {
        var items = [];
        var point = document.createElementNS("http://www.w3.org/2000/svg", "svg").createSVGPoint();

        document.addEventListener('lazybeforeunveil', function (e) {
            if (e.target.tagName.toLowerCase() === 'svg') {
                const imageEl = e.target.querySelector('image');
                if (imageEl) {
                    const newImageUrl = imageEl.getAttribute('data-href');
                    imageEl.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', newImageUrl);
                }
            }
        });

        function getCoordinates(e, svg) {
            if (!svg || !svg.getScreenCTM) {
                console.error('SVG or getScreenCTM not available');
                return null;
            }
            point.x = e.clientX;
            point.y = e.clientY;
            return point.matrixTransform(svg.getScreenCTM().inverse());
        }

        function Item(config) {
            Object.keys(config).forEach(function (item) {
                this[item] = config[item];
            }, this);
            if (this.svg) {
                this.el.addEventListener('mousemove', this.mouseMoveHandler.bind(this));
                this.el.addEventListener('mouseenter', this.mouseEnterHandler.bind(this));
                this.el.addEventListener('mouseleave', this.mouseLeaveHandler.bind(this));
            }
        }

        Item.prototype = {
            update: function (c) {
                if (c && this.clip) {
                    this.clip.setAttribute('cx', c.x);
                    this.clip.setAttribute('cy', c.y);
                }
            },
            mouseMoveHandler: function (e) {
                const coordinates = getCoordinates(e, this.svg);
                if (coordinates) {
                    this.update(coordinates);
                } else {
                    console.error('Coordinates not available');
                }
            },
            mouseEnterHandler: function (e) {
                if (this.clip) {
                    this.clip.setAttribute('r', 150);
                }
            },
            mouseLeaveHandler: function (e) {
                if (this.clip) {
                    this.clip.setAttribute('r', 0);
                }
            }
        };

        document.querySelectorAll('.team-image').forEach(function (item, index) {
            const svg = item.querySelector('svg');
            if (!svg) {
                console.error('SVG not found for item', item);
                return;
            }
            const clip = svg.querySelector(`#clip-${index} .mask-overlay`);
            if (!clip) {
                console.error('Clip not found for SVG', svg);
                return;
            }

            items.push(new Item({
                el: item,
                svg: svg,
                clip: clip
            }));
        });
    }
});
